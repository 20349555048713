import React from 'react'
import styled from '@emotion/styled'
import { H1 } from '../components/shared/type'
import Container from '../components/shared/container'
import Layout from '../components/layout'
import PageMetadata from '../components/page-metadata'

const Title = styled(H1)``

const NotFoundPage = ({ location }) => (
  <Layout location={location}>
    <PageMetadata title="404: Not found" />
    <Container>
      <Title>NOT FOUND</Title>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Container>
  </Layout>
)

export default NotFoundPage
